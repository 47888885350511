










































import {Component, Prop, Vue} from 'vue-property-decorator';
import BaseDialog from '@/components/BaseDialog.vue';
import SearchView from '@/components/SearchView.vue';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import ContactsList2 from '@/components/ContactsList2.vue';
import ContactsList from '@/components/ContactsList.vue';
import {BusinessContact} from '@/domain/model/types';
import {Action, Getter} from 'vuex-class';
import {profileStore} from '@/store/modules/profile';
import {mixins} from 'vue-class-component';
import Notifications from '@/components/mixins/Notifications';

@Component({
  name: 'dialog-create-new-chat',
  components: {ContactsList, ContactsList2, AvatarWithStatus, SearchView, BaseDialog},
})
export default class DialogCreateNewChat extends mixins(Notifications) {
  @Prop() value!: boolean;

  selectedContacts: BusinessContact[] = []
  chatName: string = ''

  @Getter chatsBusy
  @Getter chatsError

  get show() {
    return this.value;
  }

  set show(value: boolean) {
    this.$emit('input', value);
  }

  get filterIDs() {
    return [profileStore.t2bUser!.id];
  }

  get showGroupName() {
    return this.selectedContacts.length > 1
  }

  get isNameValid(): boolean {
    if (this.showGroupName && this.chatName.length > 30)
      return false
    return true
  }

  @Action createInnerChat

  onContactsSelected(contacts: BusinessContact[]) {
    this.selectedContacts = contacts;
  }

  async onCreateChat() {
    const data = {
      contactIds: this.selectedContacts.map((item) => item.id),
      title: this.chatName || null
    };
    const chatId = await this.createInnerChat(data);
    if (!!chatId) {
      console.log(`chat created. chatId: ${chatId}`);

      await this.$router.push({
        name: 'inner-chat',
        params: {chatId},
        query: {type: 'inner', subtype: 'personal'}
      }).catch(error => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      })
      this.show = false
    } else {
      this.showIssue(this.chatsError);
    }
  }
}
